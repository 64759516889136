import { useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import Seo from "../components/seo";

const Page = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "21832176",
          formId: "08534576-4ec9-4f88-943d-ac46bc8af99d",
          target: "#form-container",
        });
      }
    });
  }, []);

  return (
    <Layout>
      <Seo title="Contact Us" />

      <div className="container text-center mb-5">
        <div className="row text-left">
          <h1>Let's chat</h1>
          <span>
            Fill out the form below and a DocuZen expert will be in touch.
          </span>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="my-4" id="form-container"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page as any;
